var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"elevation":"2","type":"article, divider, list-item-avatar, list-item-avatar, list-item-avatar"}}):_c('v-card',{attrs:{"min-width":_vm.minWidth,"max-width":_vm.maxWidth}},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.request.title))]),_c('v-spacer'),(_vm.request.status !== 'planned')?_c('v-chip',{class:_vm.statusTextClass(_vm.request.status),attrs:{"color":_vm.statusColor(_vm.request.status)}},[_vm._v(_vm._s(_vm.statusText(_vm.request.status)))]):_vm._e(),(_vm.showCloseButton)?_c('v-btn',{attrs:{"color":_vm.$vuetify.theme.dark ? '' : 'secondary',"icon":""},on:{"click":function($event){return _vm.$emit('closeEvent')}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-card-subtitle',[_c('v-chip',{class:`${_vm.getMaterialColor(
          _vm.request.type.color
        )}--text text--darken-4 font-weight-medium`,attrs:{"small":"","color":`${_vm.getMaterialColor(_vm.request.type.color)} lighten-4`}},[_vm._v(" "+_vm._s(_vm.request.type.title)+" ")])],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock-start")])],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.timestampConverter(_vm.request.timeSlots.start.timestamp))+" Uhr ")])],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock-end")])],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.timestampConverter(_vm.request.timeSlots.end.timestamp))+" Uhr ")])],1),(_vm.request.location.name)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-map-marker")])],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.request.location.name))])],1):_vm._e(),(_vm.request.description)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar-text")])],1),_c('v-list-item-subtitle',{staticStyle:{"white-space":"normal"}},[_vm._v(_vm._s(_vm.request.description))])],1):_vm._e(),(_vm.request.config.organizer.isAppointed)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-hard-hat")])],1),_c('v-list-item-subtitle',{staticStyle:{"white-space":"normal"}},[_vm._v("Organisation: "),(
            _vm.request.config.organizer.description !== null &&
            _vm.request.config.organizer.description !== ''
          )?_c('v-chip',{staticClass:"ml-2 my-2",attrs:{"label":"","small":""}},[_vm._v(_vm._s(_vm.request.config.organizer.description))]):_vm._e(),_vm._l((_vm.request.config.organizer.personData),function(item){return _c('v-chip',{key:item.uid,staticClass:"ml-2 my-2",attrs:{"label":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-circle")]),_vm._v(_vm._s(item.displayName))],1)})],2)],1):_vm._e(),(_vm.request.config.attendance.isLimited)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-multiple-plus")])],1),_c('v-list-item-subtitle',{staticStyle:{"white-space":"normal"}},[_vm._v("Veranstaltung für: "),(
            _vm.request.config.attendance.description !== null &&
            _vm.request.config.attendance.description !== ''
          )?_c('v-chip',{staticClass:"ml-2 my-2",attrs:{"label":"","small":""}},[_vm._v(_vm._s(_vm.request.config.attendance.description))]):_vm._e(),_vm._l((_vm.request.config.attendance.allowedUserGroups),function(item){return _c('v-chip',{key:item.id,staticClass:"ml-2 my-2",attrs:{"label":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-folder-account")]),_vm._v(_vm._s(item.title))],1)}),_vm._l((_vm.request.config.attendance.allowedPersons),function(item){return _c('v-chip',{key:item.uid,staticClass:"ml-2 my-2",attrs:{"label":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-circle")]),_vm._v(_vm._s(item.displayName))],1)})],2)],1):_vm._e(),(_vm.showDetailsButton)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","depressed":"","to":{
          name: 'availability-request-details-tab-general',
          params: { itemId: _vm.request.meta.id },
        }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-information-outline")]),_vm._v("Details")],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }