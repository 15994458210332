<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <!-- <Subheader
                icon="mdi-calendar-question"
                title="Rückmeldungen"
                :actions="actions"
              >
              </Subheader> -->
              <div class="mt-7">
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-skeleton-loader
                      v-if="helpers.dataIsLoading"
                      elevation="2"
                      type="article"
                    ></v-skeleton-loader>
                    <v-card v-else>
                      <v-card-subtitle>Besetzungsmöglichkeiten</v-card-subtitle>
                      <v-card-text>
                        <v-chip
                          color="green lighten-4"
                          label
                          class="font-weight-medium green--text text--darken-4 mr-2 mb-2"
                          ><v-icon left small>mdi-check-circle</v-icon
                          >{{
                            countTimeSlotsByStatus("target") +
                            countTimeSlotsByStatus("overhang")
                          }}
                          Besetzbar</v-chip
                        >
                        <!-- <v-chip
                          color="orange lighten-4"
                          label
                          class="font-weight-medium orange--text text--darken-4 mr-2 mb-2"
                          ><v-icon left small>mdi-minus-circle</v-icon>4 Fast
                          besetzbar</v-chip
                        > -->
                        <v-chip
                          color="red lighten-4"
                          label
                          class="font-weight-medium red--text text--darken-4 mr-2 mb-2"
                          ><v-icon left small>mdi-close-circle</v-icon
                          >{{ countTimeSlotsByStatus("deficit") }} Nicht
                          besetzbar</v-chip
                        >
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <!-- <v-col cols="12" sm="12" md="6" v-if="checkIfSupport">
                    <v-card>
                      <v-card-subtitle
                        ><v-icon x-small class="mr-2">mdi-tools</v-icon
                        >Rückmeldungen in Datensatz
                      </v-card-subtitle>
                      <v-card-text> </v-card-text>
                    </v-card>
                  </v-col> -->
                  <v-col cols="12" sm="12" md="6">
                    <v-skeleton-loader
                      v-if="helpers.dataIsLoading"
                      elevation="2"
                      type="article"
                    ></v-skeleton-loader>
                    <v-card v-else>
                      <v-card-subtitle
                        >Zusammenfassung der Rückmeldungen</v-card-subtitle
                      >
                      <v-card-text>
                        <v-chip
                          color="green lighten-4"
                          label
                          class="font-weight-medium green--text text--darken-4 mr-2 mb-2"
                          >{{
                            totalResponsesCount.complete
                          }}
                          Beantwortet</v-chip
                        >
                        <v-chip
                          color="orange lighten-4"
                          label
                          class="font-weight-medium orange--text text--darken-4 mr-2 mb-2"
                          >{{
                            totalResponsesCount.incomplete
                          }}
                          Unvollständig</v-chip
                        >
                        <v-chip
                          color="red lighten-4"
                          label
                          class="font-weight-medium red--text text--darken-4 mr-2 mb-2"
                          >{{
                            totalResponsesCount.notResponded
                          }}
                          Unbeantwortet</v-chip
                        >
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-skeleton-loader
                      v-if="helpers.dataIsLoading"
                      elevation="2"
                      type="text: text"
                    ></v-skeleton-loader>
                    <v-card
                      v-else-if="
                        !helpers.dataIsLoading &&
                        requestResponsesMatchedComments.length > 0
                      "
                    >
                      <v-card-subtitle
                        ><v-btn
                          class="mr-2"
                          icon
                          depressed
                          @click="
                            helpers.showResponseComments =
                              !helpers.showResponseComments
                          "
                          ><v-icon>{{
                            helpers.showResponseComments
                              ? "mdi-chevron-up"
                              : "mdi-chevron-down"
                          }}</v-icon></v-btn
                        >Kommentare<v-spacer></v-spacer
                      ></v-card-subtitle>
                      <v-expand-transition>
                        <div v-show="helpers.showResponseComments">
                          <v-divider></v-divider>

                          <!-- <v-list> -->
                          <v-list-item
                            v-for="response in requestResponsesMatchedComments"
                            :key="response.meta.uid"
                          >
                            <v-avatar
                              rounded
                              size="46"
                              :color="
                                $vuetify.theme.dark
                                  ? 'rgba(255, 255, 255, 0.12)'
                                  : 'grey lighten-2'
                              "
                              class="font-weight-medium mr-4"
                              >{{
                                initials(response.user.displayName)
                              }}</v-avatar
                            >

                            <v-list-item-content>
                              <v-list-item-title class="font-weight-medium">{{
                                response.user.displayName
                              }}</v-list-item-title>
                              <v-list-item-subtitle class="text-wrap d-flex"
                                ><v-icon small class="mr-3"
                                  >mdi-comment-text</v-icon
                                >{{ response.comment }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <!-- </v-list> -->
                        </div>
                      </v-expand-transition>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-skeleton-loader
                      v-if="helpers.dataIsLoading"
                      elevation="2"
                      type="table-heading, table-thead, table-tbody"
                    ></v-skeleton-loader>
                    <v-card v-else>
                      <v-data-table
                        v-model="helpers.eventCreation.selectedTimeSlots"
                        :headers="computedHeaders"
                        :items="timeSlotsDataOutput"
                        disable-filtering
                        item-key="id"
                        dense
                        expandable
                        show-expand
                        :show-select="
                          checkPermission(manageTimeSlotsPermissionId) &&
                          checkPermission(createEventsPermissionId)
                        "
                        :expanded.sync="helpers.expandedTimeSlots"
                        mobile-breakpoint="0"
                        :footer-props="footerProps"
                        :items-per-page="15"
                        no-data-text="Keine Terminoptionen für diese Abfrage vorhanden"
                      >
                        <template v-slot:top>
                          <v-card flat>
                            <v-card-title>
                              <span class="mr-4 subtitle-2 font-weight-medium"
                                >Filter</span
                              >
                              <v-btn-toggle
                                v-model="helpers.timeSlotsFilter"
                                dense
                                borderless
                                mandatory
                                class="my-2"
                              >
                                <v-btn small value="all"> Alle </v-btn>
                                <v-btn small value="onlyPossible"
                                  >Besetzbare
                                </v-btn>
                                <v-btn small value="onlyNotPossible">
                                  Nicht besetzbare</v-btn
                                >
                              </v-btn-toggle>
                              <v-spacer></v-spacer>
                              <v-btn
                                v-if="
                                  checkPermission(
                                    manageTimeSlotsPermissionId
                                  ) && checkPermission(createEventsPermissionId)
                                "
                                depressed
                                :disabled="
                                  !helpers.eventCreation.selectedTimeSlots
                                    .length > 0
                                "
                                color="primary"
                                @click="toggleEventCreationDialog()"
                                >Veranstaltungen für Auswahl erstellen</v-btn
                              >
                            </v-card-title>
                          </v-card>
                        </template>
                        <template v-slot:item.priority="{ item }">
                          <v-chip
                            :color="priorityColor(item.priority)"
                            x-small
                            :class="priorityStyle(item.priority)"
                            >PRIO {{ item.priority }}</v-chip
                          >
                        </template>
                        <template v-slot:item.requestedResources="{ item }">
                          <v-chip
                            label
                            x-small
                            class="mx-1 my-2"
                            v-if="item.requestedResources.minPersons"
                            >Min.:
                            {{ item.requestedResources.minPersons }}</v-chip
                          ><v-chip
                            x-small
                            class="mx-1 my-2"
                            v-if="item.requestedResources.maxPersons"
                            >Max.:
                            {{ item.requestedResources.maxPersons }}</v-chip
                          >
                        </template>
                        <template v-slot:item.resourceStatus.status="{ item }">
                          <v-chip
                            :color="statusColor(item.resourceStatus.status)"
                            small
                            label
                            :class="[
                              'font-weight-medium text--darken-4 my-2',
                              statusStyle(item.resourceStatus.status),
                            ]"
                            ><v-icon left small>{{
                              statusIcon(item.resourceStatus.status)
                            }}</v-icon
                            >{{
                              statusText(
                                item.resourceStatus.status,
                                item.responses.confirmations.length +
                                  item.responses.ifNecessary.length,
                                item.requestedResources.minPersons,
                                item.requestedResources.maxPersons
                              )
                            }}</v-chip
                          >
                          <v-btn
                            icon
                            depressed
                            v-if="item.eventCreated"
                            :to="{
                              name: 'scheduling-event-details-tab-general',
                              params: { itemId: item.id },
                            }"
                            ><v-icon small>mdi-calendar</v-icon></v-btn
                          >
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                            <v-row class="my-5">
                              <v-col cols="12" sm="12" md="12">
                                <v-card outlined>
                                  <v-card-title
                                    class="subtitle-1 font-weight-medium"
                                    >Rückmeldungen<v-spacer></v-spacer>
                                    <response-statistics-card
                                      marginClass="mr-2"
                                      :totalConfirmations="
                                        item.count.totalConfirmations
                                      "
                                      :totalIfNecessary="
                                        item.count.totalIfNecessary
                                      "
                                      :totalCancellations="
                                        item.count.totalCancellations
                                      "
                                      :totalNotInTown="item.count.totalAway"
                                      :totalAwaitingResponse="
                                        item.count.totalAwaitingResponse
                                      "
                                      :totalResponses="
                                        item.count.totalResponses
                                      "
                                      small
                                    ></response-statistics-card
                                  ></v-card-title>
                                  <v-divider></v-divider>
                                  <v-card-text
                                    v-if="
                                      item.responses.confirmations.length > 0
                                    "
                                  >
                                    <div>
                                      <v-chip
                                        label
                                        color="green lighten-4"
                                        class="mb-2 green--text text--darken-4"
                                      >
                                        <v-icon small>mdi-check-circle</v-icon>
                                        <span class="ml-1 font-weight-medium"
                                          >Verfügbar</span
                                        >
                                      </v-chip>
                                    </div>
                                    <v-chip
                                      v-for="userResponse in item.responses
                                        .confirmations"
                                      :key="userResponse.user.uid"
                                      small
                                      label
                                      class="mr-2 mb-2"
                                      >{{
                                        userResponse.user.displayName
                                      }}</v-chip
                                    >
                                  </v-card-text>
                                  <v-divider
                                    v-if="
                                      item.responses.confirmations.length > 0
                                    "
                                  >
                                  </v-divider>
                                  <v-card-text
                                    v-if="item.responses.ifNecessary.length > 0"
                                  >
                                    <div>
                                      <v-chip
                                        label
                                        color="orange lighten-4"
                                        class="mb-2 orange--text text--darken-4"
                                      >
                                        <v-icon small>mdi-minus-circle</v-icon>
                                        <span class="ml-1 font-weight-medium"
                                          >Wenn notwendig</span
                                        >
                                      </v-chip>
                                    </div>
                                    <v-chip
                                      v-for="userResponse in item.responses
                                        .ifNecessary"
                                      :key="userResponse.user.uid"
                                      small
                                      label
                                      class="mr-2 mb-2"
                                      >{{
                                        userResponse.user.displayName
                                      }}</v-chip
                                    >
                                  </v-card-text>
                                  <v-divider
                                    v-if="item.responses.ifNecessary.length > 0"
                                  >
                                  </v-divider>
                                  <v-card-text
                                    v-if="
                                      item.responses.cancellations.length > 0
                                    "
                                  >
                                    <div>
                                      <v-chip
                                        label
                                        color="red lighten-4"
                                        class="mb-2 red--text text--darken-4"
                                      >
                                        <v-icon small>mdi-close-circle</v-icon>
                                        <span class="ml-1 font-weight-medium"
                                          >Nicht verfügbar</span
                                        >
                                      </v-chip>
                                    </div>
                                    <v-chip
                                      v-for="userResponse in item.responses
                                        .cancellations"
                                      :key="userResponse.user.uid"
                                      small
                                      label
                                      class="mr-2 mb-2"
                                      >{{
                                        userResponse.user.displayName
                                      }}</v-chip
                                    >
                                  </v-card-text>
                                  <v-divider
                                    v-if="
                                      item.responses.cancellations.length > 0
                                    "
                                  >
                                  </v-divider>
                                  <v-card-text
                                    v-if="item.responses.away.length > 0"
                                  >
                                    <div>
                                      <v-chip
                                        label
                                        color="blue lighten-4"
                                        class="mb-2 blue--text text--darken-4"
                                      >
                                        <v-icon small>mdi-airplane</v-icon>
                                        <span class="ml-1 font-weight-medium"
                                          >Nicht in Stadt</span
                                        >
                                      </v-chip>
                                    </div>
                                    <v-chip
                                      v-for="userResponse in item.responses
                                        .away"
                                      :key="userResponse.user.uid"
                                      small
                                      label
                                      class="mr-2 mb-2"
                                      >{{
                                        userResponse.user.displayName
                                      }}</v-chip
                                    >
                                  </v-card-text>
                                  <v-divider
                                    v-if="item.responses.away.length > 0"
                                  >
                                  </v-divider>
                                  <v-card-text
                                    v-if="
                                      item.responses.awaitingResponse.length > 0
                                    "
                                  >
                                    <div>
                                      <v-chip
                                        label
                                        color="brown lighten-4"
                                        class="mb-2 brown--text text--darken-4"
                                      >
                                        <v-icon small>mdi-help-circle</v-icon>
                                        <span class="ml-1 font-weight-medium"
                                          >Keine Rückmeldung</span
                                        >
                                      </v-chip>
                                    </div>
                                    <v-chip
                                      v-for="userResponse in item.responses
                                        .awaitingResponse"
                                      :key="userResponse.user.uid"
                                      small
                                      label
                                      class="mr-2 mb-2"
                                      >{{
                                        userResponse.user.displayName
                                      }}</v-chip
                                    >
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.eventCreation.dialogModel"
        @close="toggleEventCreationDialog()"
        title="Veranstaltungen für Auswahl erstellen"
        text="Für ausgewählte Terminoptionen wird eine Veranstaltung erstellt. Daten werden aus der Abfrage und der ausgewählten Terminoption übernommen. Weitere Annpassungen können nach der Erstellung vorgenommen werden."
        width="m"
        :errorMessage="helpers.eventCreation.errorMessage"
        :btnAction="startEventCreation"
        :btnDisabled="!helpers.eventCreation.formIsValid"
        :loading="helpers.eventCreation.loading"
        btnText="Erstellen"
      >
        <template v-slot:content>
          <v-form
            v-model="helpers.eventCreation.formIsValid"
            @submit.prevent="startEventCreation()"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12"
                ><v-chip label
                  >{{ helpers.eventCreation.selectedTimeSlots.length }}
                  Terminoptionen ausgewählt
                </v-chip>

                <v-alert border="left" text type="info" class="mt-3"
                  >Die Abfrage wird automatisch geschlossen und die Abgabe von
                  Rückmeldungen gesperrt.</v-alert
                >

                <v-alert
                  v-if="notPossibleTimeSlotsInSelection"
                  border="left"
                  text
                  type="warning"
                  class="mt-2"
                  >In der Auswahl sind nicht besetzbare Terminoptionen.</v-alert
                >
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <v-select
                  v-model="helpers.eventCreation.eventTypeId"
                  :items="eventTypes"
                  item-text="title"
                  item-value="meta.id"
                  :loading="helpers.eventCreation.eventTypeSelectorIsLoading"
                  outlined
                  :rules="[rules.required]"
                  label="Veranstaltungsart"
                  :disabled="helpers.eventCreation.loading"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  v-model="helpers.eventCreation.copyResponses"
                  label="Rückmeldungen aus Abfrage übernehmen"
                  hint="Rückmeldungen zu ausgewählten Terminoptionen werden in den Datensatz der Veranstaltung übernommen."
                  persistent-hint
                >
                </v-checkbox>
                <!-- <v-checkbox
                  v-model="
                    helpers.eventCreation.limitAttendanceToPositiveResponses
                  "
                  label="Teilnahme für Veranstaltung auf Mitglieder mit positiver Rückmeldung beschränken"
                  hint="Wird diese Option nicht ausgewählt, wird die Teilnahmebeschränkung der Abfrage übernommen."
                  persistent-hint
                  disabled
                >
                </v-checkbox> -->

                <!-- <v-checkbox
                  v-model="helpers.eventCreation.notifyPositiveResponses"
                  label="Personen der ausgewählten Terminoptionen benachrichtigen"
                  hint="Mitglieder mit positiver Rückmeldung in der ausgewählten Terminoptionen werden benachrichtigt."
                  persistent-hint
                  disabled
                >
                </v-checkbox> -->
              </v-col>
            </v-row>
          </v-form>
        </template>
      </custom-dialog>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <v-snackbar
      v-model="helpers.eventCreation.snackbar.model"
      class="pb-4"
      bottom
      app
      timeout="3000"
      ><v-icon color="success" class="mr-1">mdi-check-circle</v-icon>
      {{ helpers.eventCreation.snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db, functions } from "@/firebase";
// import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import ResponseStatisticsCard from "@/components/scheduling/ResponseStatisticsCard.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import { SCHEDULING, ADMIN } from "@/store/modules.js";
import { GET_EVENT_TYPES, GET_USER_LIST } from "@/store/action-types.js";
import {
  AVAILABILITY_REQUESTS_RESPONSES_write,
  SCHEDULING_EVENTS_create,
} from "@/data/permission-types.js";

export default {
  name: "availability-request-details-tab-responses",
  components: {
    // Subheader,
    SupportTools,
    ResponseStatisticsCard,
    CustomDialog,
  },
  props: {
    request: Object,
  },
  data() {
    return {
      footerProps: {
        "items-per-page-options": [15, 25, 50, -1],
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      manageTimeSlotsPermissionId: `${AVAILABILITY_REQUESTS_RESPONSES_write}`,
      createEventsPermissionId: `${SCHEDULING_EVENTS_create}`,
      data: { meta: { status: "" } },
      dialog: false,
      groupDesc: false,
      sortDesc: false,
      btnToggle: 0,
      headers: [
        { text: "Priorität", value: "priority", width: "1%" },
        { text: "Titel", value: "title" },
        { text: "Start", value: "start.timestampToReadableDate" },
        { text: "Ende", value: "end.timestampToReadableDate" },
        { text: "Anforderungen", value: "requestedResources" },
        { text: "Besetzung", value: "resourceStatus.status" },
        { text: "", value: "data-table-expand" },
      ],
      helpers: {
        showResponseComments: false,
        timeSlotsFilter: "all",
        dataIsLoading: true,
        expandedTimeSlots: [],
        eventCreation: {
          copyResponses: true,
          eventId: "",
          eventTypeSelectorIsLoading: true,
          selectedTimeSlots: [],
          dialogModel: false,
          formIsValid: false,
          loading: false,
          errorMessage: "",
          snackbar: {
            model: false,
            message: "",
          },
        },
      },

      requestResponses: [],
      status: ["1", "3", "2", "4", "0"],
      userResponse: {
        user: {
          uid: null,
        },
        status: { id: null },
        comment: null,
      },
      items: [
        {
          value: "-1",
          icon: "mdi-cancel",
          disabled: true,
          status: "Nicht eingeladen",
          color: "purple darken-2",
        },
        {
          value: "0",
          icon: "mdi-help",
          disabled: true,
          status: "Keine Rückmeldung",
          color: "blue-grey darken-2",
        },
        {
          value: "1",
          icon: "mdi-check",
          status: "Verfügbar",
          color: "success",
        },
        {
          value: "2",
          icon: "mdi-close",
          status: "Nicht verfügbar",
          color: "error",
        },
        {
          value: "3",
          icon: "mdi-minus",
          status: "Wenn notwendig",
          color: "warning",
        },
        {
          value: "4",
          icon: "mdi-airplane",
          status: "Nicht in Stadt / Urlaub",
          color: "info",
        },
      ],
    };
  },
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    code() {
      return [
        { title: "request", data: this.request },
        { title: "requestResponses", data: this.requestResponses },
        { title: "timeSlotsData", data: this.timeSlotsData },
        { title: "membershipsPrepared", data: this.membershipsPrepared },
        {
          title: "requestResponsesMatched",
          data: this.requestResponsesMatched,
        },
        {
          title: "membershipsPreparedDisplayName",
          data: this.membershipsPrepared.map((a) => a.user.displayName),
        },
      ];
    },
    notPossibleTimeSlotsInSelection() {
      return this.helpers.eventCreation.selectedTimeSlots.some(
        (item) => item.resourceStatus.status === "deficit"
      );
    },
    totalResponsesCount() {
      const responses = this.requestResponsesMatched || [];
      let complete = 0;
      let incomplete = 0;
      let notResponded = 0;
      // Check for each response if it is complete, incomplete or not responded and adjust the count accordingly
      responses.forEach((response) => {
        var responseStatus = this.getResponseStatus(response);
        if (responseStatus == "complete") {
          complete++;
        } else if (responseStatus == "incomplete") {
          incomplete++;
        } else if (responseStatus == "notResponded") {
          notResponded++;
        }
      });
      return {
        complete: complete,
        incomplete: incomplete,
        notResponded: notResponded,
      };
    },
    eventTypes() {
      return this.$store.state.scheduling.eventTypes;
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    membershipsPrepared() {
      var range = {
        start: this.request.timeSlots.start.timestampToDate,
        end: this.request.timeSlots.end.timestampToDate,
      };
      var membershipsFiltered = this.memberships.filter((obj) => {
        return (
          new Date(obj.user.memberSinceToDate) <= new Date(range.start) && // All members that where members before or at the start of this request
          !(new Date(obj.user.memberUntilToDate) <= new Date(range.end)) && // All members that didn't leave before or at the request of this request
          obj.access.type === "member"
        );
      });

      var memberships = membershipsFiltered;
      // if (this.request.status !== "planned") {}
      const result = memberships.map((item) => {
        const container = {
          group: {
            title: item.group.title,
            sortKey: item.group.sortKey,
            // groupBy:
            //   item.group.sortKey.toString().padStart(4, "0") +
            //   "_" +
            //   item.group.title,
            id: item.group.id,
          },
          user: {
            displayName: item.user.displayName,
            uid: item.user.uid,
            // photoURL = item.user.photoURL,
            // position = item.user.dutyPosition,
          },
        };
        return container;
      });
      return result;
    },
    requestResponsesMatched() {
      const memberships = this.membershipsPrepared;
      // 1: Match memberships to request status
      const matched = this.requestResponses.map((response) => {
        const matchedMembership = memberships.find(
          (membership) => membership.user.uid === response.meta.uid
        );
        return { ...response, ...matchedMembership };
      });

      // 2: Find missing memberships
      const missing = memberships.filter((o) =>
        matched.every((s) => s.user.uid !== o.user.uid)
      );

      // 3: Create request status for missing memberships
      var addedStatus = [];
      if (missing.length > 0) {
        addedStatus = missing.map((obj) => ({
          ...obj,
          comment: "",
          meta: {
            lastUpdatedBy: "",
          },
          status: [],
          // groupBy: "5_Keine Rückmeldung",
        }));
      }

      // 4: Combine both groups as output
      let output = matched.concat(addedStatus);

      // 5: Delete items with user.uid or group.id not in allowedAttendeesIds, excluding items with status.id !== "0"
      if (this.request.config.attendance.isLimited) {
        const allowedAttendeesIds =
          this.request.config.attendance.allowedAttendeesIds;
        const outputFiltered = output.filter((obj) => {
          return (
            allowedAttendeesIds.includes(obj.user.uid) ||
            allowedAttendeesIds.includes(obj.group.id) ||
            obj.status.length === 0
          );
        });

        return outputFiltered;
      }
      return output;
    },
    requestResponsesMatchedComments() {
      return this.requestResponsesMatched.filter((response) => {
        return response.comment !== "";
      });
    },
    // sortBy() {
    //   if (this.btnToggle == 0) {
    //     return "user.displayName";
    //   } else {
    //     return "user.displayName";
    //   }
    // },
    // groupBy() {
    //   if (this.btnToggle == 0) {
    //     return "status.groupBy";
    //   } else {
    //     return "group.groupBy";
    //   }
    // },
    // return an object with a key (from timeslot.id) for each timeSlot; the object should have the following fields: "status", count: "", responses: [
    timeSlotsData() {
      const timeSlots = this.request.timeSlots.data;
      const responses = this.requestResponsesMatched;
      const timeSlotResponsesData = timeSlots.map((timeSlot) => {
        const matchingResponses = responses
          .filter((response) => {
            return response.status.some((status) => status.id === timeSlot.id);
          })
          .map((response) => {
            const matchingStatus = response.status.find((status) => {
              return status.id === timeSlot.id;
            });
            return {
              ...response,
              status: matchingStatus,
            };
          });
        return {
          ...timeSlot,
          responses: {
            all: matchingResponses,
            confirmations: matchingResponses.filter(
              (response) => response.status.status === "1"
            ),
            cancellations: matchingResponses.filter(
              (response) => response.status.status === "2"
            ),
            ifNecessary: matchingResponses.filter(
              (response) => response.status.status === "3"
            ),
            away: matchingResponses.filter(
              (response) => response.status.status === "4"
            ),
            awaitingResponse: matchingResponses.filter(
              (response) => response.status.status === "0"
            ),
          },
          count: {
            totalResponses: this.getStatusCount("all", matchingResponses),
            totalConfirmations: this.getStatusCount("1", matchingResponses),
            totalCancellations: this.getStatusCount("2", matchingResponses),
            totalIfNecessary: this.getStatusCount("3", matchingResponses),
            totalAway: this.getStatusCount("4", matchingResponses),
            totalAwaitingResponse: this.getStatusCount("0", matchingResponses),
          },
          resourceStatus: this.getTimeslotStatus(
            timeSlot.requestedResources,
            matchingResponses
          ),
        };
      });
      return timeSlotResponsesData;
    },
    timeSlotsDataOutput() {
      if (this.helpers.timeSlotsFilter === "onlyPossible") {
        return this.timeSlotsData.filter(
          (timeSlot) =>
            timeSlot.resourceStatus.status === "target" ||
            timeSlot.resourceStatus.status === "overhang"
        );
      } else if (this.helpers.timeSlotsFilter === "onlyNotPossible") {
        return this.timeSlotsData.filter(
          (timeSlot) => timeSlot.resourceStatus.status === "deficit"
        );
      } else {
        return this.timeSlotsData;
      }
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
    request: function (newValue) {
      this.data = newValue;
      if (newValue !== null) {
        this.getMemberships();
      }
    },
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("requests")
        .doc(this.$route.params.itemId)
        .collection("requestResponses")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              ...doc.data(),
            };
            if (
              data.meta.lastUpdatedBy &&
              data.meta.lastUpdatedBy !== data.meta.uid
            ) {
              data.meta.lastUpdatedByDisplayName = this.getDisplayName(
                data.meta.lastUpdatedBy
              );
            }
            this.requestResponses.push(data);
          });
        })
        .then(() => {
          return this.$store.dispatch(`${SCHEDULING}${GET_EVENT_TYPES}`, {
            organizationId: this.$route.params.organizationId,
          });
        })
        .then(() => {
          this.helpers.eventCreation.eventTypeSelectorIsLoading = false;
          this.helpers.dataIsLoading = false;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getTimeslotStatus(requestedResources, responses) {
      // Check if there are enough responses to fill the requested resources
      // 1: Min-Check
      const responseCount =
        this.getStatusCount("1", responses) +
        this.getStatusCount("3", responses);

      const minCheck = requestedResources.minPersons
        ? responseCount >= requestedResources.minPersons
        : responseCount >= 1;

      // 2: Max-Check
      const maxCheck = requestedResources.maxPersons
        ? responseCount <= requestedResources.maxPersons
        : true;

      // 3: Unit-Check
      const unitCheck = true;

      // statusResult should output the result of minCheck, maxCheck and unitCheck; if all are true, the status is "target", else it's "deficit" if minCheck is false and "overhang" if maxCheck is false
      const statusResult =
        minCheck && maxCheck && unitCheck
          ? "target"
          : !minCheck
          ? "deficit"
          : "overhang";

      return {
        status: statusResult,
        minCheck: minCheck,
        maxCheck: maxCheck,
        unitCheck: unitCheck,
      };
    },
    initials(fullName) {
      const namesArray = fullName.trim().split(" ");
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else
        return `${namesArray[0].charAt(0)}${namesArray[
          namesArray.length - 1
        ].charAt(0)}`;
    },
    statusText(status, count, min, max) {
      if (!min) {
        min = 0;
      }
      var additionalInfoCount;
      const showCount = true;
      const hideInfoText = false;
      switch (status) {
        case "deficit":
          additionalInfoCount = count - min;
          return (
            (hideInfoText ? "" : "Nicht besetzbar") +
            (showCount
              ? additionalInfoCount === 0
                ? ""
                : " (" + additionalInfoCount + ")"
              : "")
          );

        case "target":
          return hideInfoText ? "" : "Besetzbar";
        case "overhang":
          additionalInfoCount = count - max;
          return (
            (hideInfoText ? "" : "Besetzbar (Überhang)") +
            (showCount
              ? additionalInfoCount === 0
                ? ""
                : " (" + additionalInfoCount + ")"
              : "")
          );
        default:
          "";
      }
    },
    statusColor(status) {
      switch (status) {
        case "deficit":
          return "red lighten-4";
        case "target":
          return "green lighten-4";
        case "overhang":
          return "orange lighten-4";
        default:
          "";
      }
    },
    statusIcon(status) {
      switch (status) {
        case "deficit":
          return "mdi-arrow-down-circle";
        case "target":
          return "mdi-check-circle";
        case "overhang":
          return "mdi-arrow-up-circle";
        default:
          "";
      }
    },
    statusStyle(status) {
      switch (status) {
        case "deficit":
          return "red--text";
        case "target":
          return "green--text";
        case "overhang":
          return "orange--text";
        default:
          "";
      }
    },
    checkPermission(item) {
      if (item == "" || item == undefined) {
        return true;
      } else {
        return this.$store.getters["organization/checkPermissionByID"](item);
      }
    },
    getStatusCount(statusId, responses) {
      if (statusId == "all") {
        return (
          this.countStatusById("1", responses) +
          this.countStatusById("2", responses) +
          this.countStatusById("3", responses) +
          this.countStatusById("4", responses)
        );
      } else {
        return this.countStatusById(statusId, responses);
      }
    },
    countStatusById(statusId, responses) {
      return responses.reduce(function (n, response) {
        return n + (response.status.status == statusId);
      }, 0);
    },
    countTimeSlotsByStatus(status) {
      return this.timeSlotsData.reduce(function (n, response) {
        return n + (response.resourceStatus.status == status);
      }, 0);
    },
    checkifUserIsInvited(uid, userGroupId, attendance) {
      if (attendance.isLimited) {
        return (
          attendance.allowedAttendeesIds.includes(uid) ||
          attendance.allowedAttendeesIds.includes(userGroupId) ||
          attendance.allowedAttendeesIds.includes("all")
        );
      } else {
        return true;
      }
    },
    getResponseStatus(response) {
      const timeSlots = this.request.timeSlots.data;
      const responseIsComplete = timeSlots.every((timeSlot) => {
        const matchingResponse = response.status.find(
          (response) => response.id === timeSlot.id
        );
        return matchingResponse;
      });
      if (responseIsComplete) {
        return "complete";
      } else if (
        response.status.length > 0 &&
        timeSlots.length !== response.status.length
      ) {
        // Response can't be complete if there are more time slots than responses
        return "incomplete";
      } else if (
        !this.checkifUserIsInvited(
          response.meta.uid,
          response.group.id,
          this.request.config.attendance
        )
      ) {
        return "not invited";
      } else {
        return "notResponded";
      }
    },

    getMemberships() {
      if (this.data.status === "planned") {
        this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: true,
          query: {
            type: "active",
          },
        });
      } else {
        this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: false,
          query: {
            type: "archived",
            memberUntil: this.convertToDate(
              this.request.timeSlots.start.timestamp
            ),
          },
        });
      }
    },
    getDisplayName(uid) {
      const user = this.memberships.find(
        (membership) => membership.user.uid === uid
      );
      return user.user.displayName;
    },

    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    priorityColor(item) {
      switch (item) {
        case 1:
          return "pink lighten-4";
        case 2:
          return "deep-orange lighten-4";
        case 3:
          return "lime lighten-4";
        default:
          return "";
      }
    },
    priorityStyle(item) {
      switch (item) {
        case 1:
          return "pink--text text--darken-4 font-weight-medium";
        case 2:
          return "deep-orange--text text--darken-4 font-weight-medium";
        case 3:
          return "lime--text text--darken-4 font-weight-medium";
        default:
          return "";
      }
    },
    toggleEventCreationDialog() {
      this.helpers.eventCreation.dialogModel =
        !this.helpers.eventCreation.dialogModel;
    },
    startEventCreation() {
      if (
        this.helpers.eventCreation.formIsValid &&
        this.helpers.eventCreation.selectedTimeSlots.length > 0
      ) {
        this.helpers.eventCreation.loading = true;

        const callFunction = functions.httpsCallable(
          "availability-createEventsFromRequest"
        );
        callFunction({
          organizationId: this.$route.params.organizationId,
          requestId: this.$route.params.itemId,
          timeSlotIds: this.helpers.eventCreation.selectedTimeSlots.map(
            (item) => item.id
          ),
          copyResponses: this.helpers.eventCreation.copyResponses,
          eventTypeId: this.helpers.eventCreation.eventTypeId,
          limitAttendanceToPositiveResponses:
            this.helpers.eventCreation.limitAttendanceToPositiveResponses,
        })
          .then((result) => {
            console.log(result.data);
            if (result.data.success) {
              this.toggleEventCreationDialog();
              this.helpers.eventCreation.loading = false;
              this.helpers.eventCreation.selectedTimeSlots = [];
              this.helpers.eventCreation.snackbar.model = true;
              this.helpers.eventCreation.snackbar.text =
                "Veranstaltungen für Auswahl erfolgreich erstellt";
            } else {
              this.helpers.eventCreation.loading = false;
              this.helpers.eventCreation.errorMessage =
                "Fehler: Versuche es später erneut.";
            }
          })
          .catch((error) => {
            this.helpers.eventCreation.loading = false;
            console.error(error.errorMsg);
            console.error(error);
            this.helpers.eventCreation.errorMessage = error.message;
          });
      } else {
        alert("Fehler: Prüfe Deine Daten und versuche es erneut");
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        (date.getDate() < 10 ? "0" : "") +
        date.getDate() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        date.getFullYear()
      );
    },
  },
};
</script>

<style>
.parent-tr {
  height: 80px;
}
</style>
