<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <!-- <Subheader icon="mdi-calendar" title="Allgemein"></Subheader> -->
              <div class="mt-7">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <request-details-card
                      :loading="helpers.dataIsLoading"
                      :request="data"
                    ></request-details-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-skeleton-loader
                      v-if="helpers.dataIsLoading"
                      elevation="2"
                      type="table-heading, divider, table-thead, table-tbody, divider, actions"
                    ></v-skeleton-loader>

                    <v-card
                      v-if="
                        !helpers.dataIsLoading &&
                        checkPermission(manageOwnResponsePermission)
                      "
                    >
                      <v-card-title
                        ><span>Meine Rückmeldung</span>
                        <v-spacer></v-spacer>
                        <v-chip
                          v-if="data.config.responses.areLocked"
                          color="purple lighten-4"
                          small
                          class="font-weight-medium purple--text text--darken-4"
                          ><v-icon small left>mdi-lock</v-icon>Rückmeldungen
                          gesperrt</v-chip
                        >
                      </v-card-title>

                      <v-card-subtitle>
                        Rückmeldefrist:
                        {{ getDeadline(data.config.responses.deadline, true) }}
                        Uhr
                        <br />
                        <v-chip
                          v-if="deadlineHasExpired"
                          small
                          class="mt-2 mr-2 font-weight-medium red--text text--darken-4"
                          color="red lighten-4"
                          ><v-icon small left>mdi-clock-remove</v-icon>Frist
                          abgelaufen</v-chip
                        >

                        <v-chip
                          v-if="data.config.attendance.isMandatory"
                          small
                          class="mt-2 mr-2 font-weight-medium red--text text--darken-4"
                          color="red lighten-4"
                          ><v-icon small left>mdi-alert-circle</v-icon
                          >Anwesenheitspflicht</v-chip
                        >
                        <v-chip
                          v-if="data.config.responses.areBinding"
                          small
                          class="mt-2 mr-2 font-weight-medium red--text text--darken-4"
                          color="red lighten-4"
                          ><v-icon small left>mdi-check-decagram</v-icon
                          >Rückmeldungen verbindlich</v-chip
                        >
                      </v-card-subtitle>
                      <v-divider></v-divider>
                      <v-card-text
                        v-if="
                          userResponse.statusExists &&
                          lastChangeWasByOtherUser()
                        "
                      >
                        <v-alert dense type="warning" text
                          >Deine Rückmeldung wurde
                          <span v-if="!userResponse.meta.createdByOtherUser"
                            >zuletzt</span
                          >
                          von
                          <span class="font-weight-medium">{{
                            lastUpdatedByDisplayName
                          }}</span>
                          am
                          {{
                            timestampConverter(userResponse.meta.lastUpdatedAt)
                          }}
                          Uhr bearbeitet.
                          <br />
                          <span class="font-weight-medium">
                            Akzeptiere die Änderungen oder passe deine
                            Rückmeldung an.</span
                          >
                        </v-alert>
                      </v-card-text>
                      <v-card-text v-if="checkIfSupport || checkIfItService">
                        <v-alert dense type="error" outlined>
                          Du verwendest ein IT-Service- oder Support-Konto und
                          kannst keine eigenen Rückmeldungen abgeben.
                        </v-alert>
                      </v-card-text>
                      <v-card-text
                        v-else-if="
                          request.config.attendance.isLimited &&
                          !request.userIsInvited
                        "
                      >
                        <v-alert dense type="error" outlined>
                          Die Teilnahme an dieser Veranstaltung ist beschränkt.
                        </v-alert>
                      </v-card-text>
                      <v-form
                        v-else
                        ref="form"
                        @submit.prevent="updateResponse()"
                      >
                        <v-card-text>
                          <v-textarea
                            v-model="userResponse.comment"
                            label="Kommentar"
                            outlined
                            rows="3"
                            auto-grow
                            clearable
                            hide-details="auto"
                            :disabled="
                              deadlineHasExpired ||
                              requestHasStarted ||
                              responseIsBinding ||
                              data.config.responses.areLocked
                            "
                          ></v-textarea>
                        </v-card-text>
                        <v-card-text>
                          <span class="mr-4 subtitle-2 font-weight-medium"
                            >Ansicht</span
                          >
                          <v-btn-toggle
                            dense
                            v-model="helpers.displayMode"
                            mandatory
                          >
                            <v-btn outlined value="detailed-list">
                              <v-icon
                                small
                                left
                                :color="
                                  $vuetify.theme.dark ? '' : 'grey darken-1'
                                "
                                >mdi-view-agenda-outline</v-icon
                              >Liste
                            </v-btn>
                            <v-btn depressed outlined value="table">
                              <v-icon
                                small
                                left
                                :color="
                                  $vuetify.theme.dark ? '' : 'grey darken-1'
                                "
                                >mdi-table</v-icon
                              >Tabelle
                            </v-btn>
                          </v-btn-toggle>
                        </v-card-text>
                        <v-list v-if="helpers.displayMode === 'detailed-list'">
                          <div
                            v-for="timeSlot in data.timeSlots.data"
                            :key="timeSlot.id"
                          >
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="subtitle-1 font-weight-medium mb-1"
                                >
                                  <v-chip
                                    v-if="timeSlot.priority"
                                    :color="priorityColor(timeSlot.priority)"
                                    x-small
                                    :class="[
                                      priorityStyle(timeSlot.priority),
                                      'mr-2',
                                    ]"
                                    >PRIO {{ timeSlot.priority }}</v-chip
                                  ><span>{{ timeSlot.title }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <p style="line-height: 1.6" class="mb-0">
                                    <v-icon small left>mdi-clock-start</v-icon
                                    >{{
                                      timeSlot.start.timestampToReadableDate
                                    }}
                                    <br />
                                    <v-icon small left>mdi-clock-end</v-icon
                                    >{{ timeSlot.end.timestampToReadableDate }}
                                  </p>
                                  <v-chip
                                    label
                                    x-small
                                    class="mx-1 my-2"
                                    v-if="
                                      timeSlot.requestedResources.minPersons
                                    "
                                    >Min.:
                                    {{
                                      timeSlot.requestedResources.minPersons
                                    }}</v-chip
                                  ><v-chip
                                    x-small
                                    class="mx-1 my-2"
                                    v-if="
                                      timeSlot.requestedResources.maxPersons
                                    "
                                    >Min.:
                                    {{
                                      timeSlot.requestedResources.maxPersons
                                    }}</v-chip
                                  >

                                  <v-chip
                                    v-for="(unitId, index) in timeSlot
                                      .requestedResources.unitIds"
                                    :key="index"
                                    label
                                    small
                                    class="ml-2 my-2"
                                    >{{ getUnitTitle(unitId) }}</v-chip
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-card flat tile>
                              <button-quick-select-editor
                                v-model="
                                  userResponse.statusObject[timeSlot.id].status
                                "
                                class="mx-3 mt-1"
                                :disabled="
                                  deadlineHasExpired ||
                                  requestHasStarted ||
                                  responseIsBinding ||
                                  data.config.responses.areLocked
                                "
                              ></button-quick-select-editor>
                            </v-card>
                            <v-divider></v-divider>
                          </div>
                        </v-list>
                        <v-data-table
                          v-else
                          :headers="headers"
                          :items="data.timeSlots.data"
                          disable-pagination
                          disable-filtering
                          disable-sort
                          hide-default-footer
                          dense
                          item-style="style"
                        >
                          <template v-slot:item.title="{ item }"
                            ><div class="d-flex flex-column">
                              <v-chip
                                v-if="item.priority"
                                :color="priorityColor(item.priority)"
                                x-small
                                :class="priorityStyle(item.priority)"
                                >PRIO {{ item.priority }}</v-chip
                              ><span class="d-block">{{ item.title }}</span>
                            </div>
                          </template>
                          <template v-slot:item.response="{ item }">
                            <button-quick-select-editor
                              v-model="
                                userResponse.statusObject[item.id].status
                              "
                              class="mx-3 mt-1"
                              :disabled="
                                deadlineHasExpired ||
                                requestHasStarted ||
                                responseIsBinding ||
                                data.config.responses.areLocked
                              "
                            ></button-quick-select-editor>
                          </template>
                          <template v-slot:item.target="{ item }">
                            <div class="d-flex flex-column">
                              <v-chip
                                label
                                x-small
                                class="mx-1 my-2"
                                v-if="item.requestedResources.minPersons"
                                >Min.:
                                {{ item.requestedResources.minPersons }}</v-chip
                              ><v-chip
                                x-small
                                class="mx-1 my-2"
                                v-if="item.requestedResources.maxPersons"
                                >Min.:
                                {{ item.requestedResources.maxPersons }}</v-chip
                              >

                              <v-chip
                                v-for="(unitId, index) in item
                                  .requestedResources.unitIds"
                                :key="index"
                                label
                                small
                                class="ml-2 my-2"
                                >{{ getUnitTitle(unitId) }}</v-chip
                              >
                            </div>
                          </template>
                        </v-data-table>
                      </v-form>
                      <v-divider
                        v-if="
                          !checkIfSupport &&
                          !checkIfItService &&
                          request.userIsInvited &&
                          !deadlineHasExpired &&
                          !requestHasStarted &&
                          !responseIsBinding
                        "
                      ></v-divider>
                      <v-card-actions
                        v-if="
                          !checkIfSupport &&
                          !checkIfItService &&
                          request.userIsInvited &&
                          !deadlineHasExpired &&
                          !requestHasStarted &&
                          !responseIsBinding &&
                          !userResponse.meta.responseAvailable &&
                          !data.config.responses.areLocked
                        "
                      >
                        <v-spacer></v-spacer>

                        <v-btn
                          depressed
                          color="success"
                          :loading="helpers.isLoading"
                          :disabled="!formIsValid"
                          @click="updateResponse()"
                        >
                          <v-icon left>mdi-content-save</v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code">
        <template v-slot:insideRow>
          <v-col cols="12" sm="12" md="12">
            <v-skeleton-loader
              v-if="helpers.dataIsLoading"
              elevation="2"
              type="card-heading, divider, list-item-avatar, list-item-avatar, list-item-avatar"
            ></v-skeleton-loader>
            <v-card v-else>
              <v-card-title> Metadaten </v-card-title>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-identifier</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  <code>{{ data.meta.id }}</code>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-timeline-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  Erstellt am
                  {{ timestampConverter(data.meta.createdAt) }} Uhr
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  Erstellt durch
                  <code>{{ data.meta.createdBy }}</code>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="!data.meta.lastUpdated == undefined">
                <v-list-item-icon>
                  <v-icon>mdi-timeline-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle
                  >Zuletzt aktualisiert am
                  {{ timestampConverter(data.meta.lastUpdatedAt) }}
                  Uhr</v-list-item-subtitle
                >
              </v-list-item>
              <v-list-item v-if="!data.meta.lastUpdated == undefined">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle
                  >Zuletzt aktualisiert durch
                  <code>{{
                    data.meta.lastUpdatedBy
                  }}</code></v-list-item-subtitle
                >
              </v-list-item>
            </v-card>
          </v-col>
        </template>
      </support-tools>
    </section>
    <!-- <Snackbar
      :model="helpers.snackbar.isVisible"
      :message="helpers.snackbar.message"
    ></Snackbar> -->
    <v-snackbar
      v-model="helpers.snackbar.isVisible"
      class="pb-4"
      bottom
      app
      timeout="3000"
      ><v-icon color="success" class="mr-1">mdi-check-circle</v-icon>
      {{ helpers.snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import RequestDetailsCard from "@/components/availability/requests/RequestDetailsCard.vue";
import ButtonQuickSelectEditor from "@/components/_system/helpers/ButtonQuickSelectEditor.vue";
import { db, auth } from "@/firebase";
import { AVAILABILITY } from "@/store/modules.js";
import { UPDATE_REQUEST_RESPONSE } from "@/store/action-types.js";
import { AVAILABILITY_REQUESTS_RESPONSES_OWN_update } from "@/data/permission-types.js";

export default {
  name: "availability-request-details-tab-general",
  components: {
    // Snackbar,
    ButtonQuickSelectEditor,
    SupportTools,
    RequestDetailsCard,
  },
  props: {
    request: Object,
    requestType: Object,
  },
  data() {
    return {
      data: null,

      headers: [
        // { text: "Priorität", value: "priority", width: "1%" },
        { text: "Titel", value: "title" },
        { text: "Start", value: "start.timestampToReadableDate" },
        { text: "Ende", value: "end.timestampToReadableDate" },
        { text: "Rückmeldung", value: "response", divider: true },
        { text: "Ziel", value: "target" },
      ],
      statusItems: [
        {
          icon: "mdi-check-circle",
          value: "1",
          loading: false,
          text: "Verfügbar",
          borderColor: { dark: "#1B5E20", light: "#1B5E20" },
          color: "green",
        },
        {
          icon: "mdi-minus-circle",
          value: "3",
          loading: false,
          text: "Wenn notwendig",
          borderColor: { dark: "#E65100", light: "#E65100" },
          color: "orange",
        },
        {
          icon: "mdi-close-circle",
          value: "2",
          loading: false,
          text: "Nicht verfügbar",
          borderColor: { dark: "#B71C1C", light: "#B71C1C" },
          color: "red",
        },
        {
          icon: "mdi-airplane",
          value: "4",
          loading: false,
          text: "Nicht in Stadt",
          borderColor: { dark: "#0D47A1", light: "#0D47A1" },
          color: "blue",
        },
      ],

      manageOwnResponsePermission: `${AVAILABILITY_REQUESTS_RESPONSES_OWN_update}`,
      userResponse: {
        status: [],
        statusExists: false,
        statusObject: {},
        comment: "",
        meta: {
          lastUpdatedBy: "",
          lastUpdatedByDisplayName: "",
        },
      },
      helpers: {
        displayMode: "detailed-list",
        isLoading: false,
        dataIsLoading: true,
        snackbar: {
          isVisible: false,
          message: "",
        },
      },
    };
  },
  computed: {
    code() {
      return [
        { title: "request", data: this.request },
        { title: "requestType", data: this.requestType },
        { title: "availabilityConfig", data: this.availabilityConfig },
        { title: "userResponse", data: this.userResponse },
      ];
    },
    formIsValid() {
      let allowedStatuses = ["1", "2", "3", "4"];

      var output = true;
      Object.keys(this.userResponse.statusObject).forEach((key) => {
        if (
          !allowedStatuses.includes(this.userResponse.statusObject[key].status)
        ) {
          output = false;
        }
      });
      return output;
    },
    convertedStatusToArray() {
      var output = [];
      Object.keys(this.userResponse.statusObject).forEach((key) => {
        output.push({
          id: key,
          status: this.userResponse.statusObject[key].status,
          comment: this.userResponse.statusObject[key].comment,
        });
      });
      return output;
    },
    availabilityConfig() {
      return this.$store.state.organization.activeOrganization.config
        .availability;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    checkIfItService() {
      return this.$store.getters["organization/checkIfItService"];
    },
    responseIsBinding() {
      var requestResponsesAreBinding = this.data.config.responses.areBinding;
      if (requestResponsesAreBinding && this.userResponse.statusExists) {
        return true;
      } else {
        return false;
      }
    },
    deadlineHasExpired() {
      if (this.data) {
        var deadline = this.getDeadline(
          this.data.config.responses.deadline,
          false
        );
        var now = new Date();

        if (now.getTime() > deadline.getTime()) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    requestHasStarted() {
      if (new Date().getTime() > this.data.timeSlots.start.timestampToDate) {
        return true;
      } else {
        return false;
      }
    },
    lastUpdatedByDisplayName() {
      return this.userResponse.meta.lastUpdatedByDisplayName;
    },
  },
  created() {
    // if (this.request !== null) {
    //   this.helpers.dataIsLoading = false;
    // }
    if (this.$route.query.changeStatus == true) {
      this.helpers.modals.changeStatus = true;
    }
    this.initialize();
  },
  watch: {
    $route: "initialize",
    request: function (newValue) {
      this.data = newValue;
    },
    "userResponse.meta.lastUpdatedByDisplayName": function () {
      // Manually trigger a DOM update when the value changes
      this.$nextTick(() => {
        // DOM is updated
      });
    },
  },
  methods: {
    getBorderStyle(active, borderColor) {
      if (this.$vuetify.theme.dark) {
        if (active) {
          return `border: 3px solid ${borderColor.dark} !important`;
        } else {
          return ``;
        }
      } else {
        if (active) {
          return `border: 3px solid ${borderColor.light} !important`;
        } else {
          return ``;
        }
      }
    },

    initialize() {
      this.data = this.request;
      if (window.outerWidth > 600) {
        this.helpers.displayMode = "detailed-list";
      }
      // 1: GET config, veranstaltung in parent
      if (this.$route.query.changeStatus == true) {
        this.helpers.modals.changeStatus = true;
      }
      // 2: GET userResponse
      // if (!this.checkIfSupport && !this.checkIfItService) {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("requests")
        .doc(this.$route.params.itemId)
        .collection("requestResponses")
        .doc(auth.currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.userResponse = doc.data();
            this.userResponse.statusExists = true;
            if (this.lastChangeWasByOtherUser()) {
              return this.addUserDisplayNameByUID(
                this.userResponse.meta.lastUpdatedBy
              );
            }
          } else {
            this.userResponse.statusExists = false;
            // doc.data() will be undefined in this case
            // console.log("No such document (no rm yet)!");
            // this.request.timeSlots.data.forEach((item) => {
            //   this.userResponse.timeSlotsStatus[item.id] = { status: "0" };
            // });
          }
          this.populateStatusObject(this.userResponse.status);
        })
        .then(() => {
          this.helpers.dataIsLoading = false;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    populateStatusObject(statusArray) {
      var newStatusObject = {};
      statusArray.forEach((item) => {
        newStatusObject[item.id] = {
          status: item.status,
          comment: item.comment,
        };
      });

      // Get timeSlotIds with missing responses
      var missingIds = [];
      const timeSlots = this.request.timeSlots.data;
      timeSlots.forEach((timeSlot) => {
        if (!statusArray.some((response) => response.id === timeSlot.id)) {
          missingIds.push(timeSlot.id);
        }
      });

      // Add missing responses to statusObject
      missingIds.forEach((item) => {
        newStatusObject[item] = { status: "0", comment: "" };
      });

      // Set statusObject
      this.userResponse.statusObject = newStatusObject;
    },
    getUnitTitle(unitId) {
      return unitId;
    },
    priorityColor(item) {
      switch (item) {
        case 1:
          return "pink lighten-4";
        case 2:
          return "deep-orange lighten-4";
        case 3:
          return "lime lighten-4";
        default:
          return "";
      }
    },
    priorityStyle(item) {
      switch (item) {
        case 1:
          return "pink--text text--darken-4 font-weight-medium";
        case 2:
          return "deep-orange--text text--darken-4 font-weight-medium";
        case 3:
          return "lime--text text--darken-4 font-weight-medium";
        default:
          return "";
      }
    },
    lastChangeWasByOtherUser() {
      var lastUpdatedBy = this.userResponse.meta.lastUpdatedBy;
      var createdBy = this.userResponse.meta.lastUpdatedBy;
      if (lastUpdatedBy == null || lastUpdatedBy == "") {
        if (createdBy !== auth.currentUser.uid) {
          this.userResponse.meta.createdByOtherUser = true;
          return true;
        } else {
          return false;
        }
      } else if (lastUpdatedBy !== auth.currentUser.uid) {
        return true;
      } else {
        false;
      }
    },
    addUserDisplayNameByUID(uid) {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("membershipsPublic")
        .doc(uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.userResponse.meta.lastUpdatedByDisplayName =
              doc.data().user.displayName;
          } else {
            console.error("ERROR:[No user with this uid.]");
          }
          console.log("done1");
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getDeadline(deadline, withFormat) {
      if (deadline.isCustom) {
        // custom deadline
        if (withFormat) {
          return this.timestampConverter(deadline.timestamp);
        } else {
          return deadline.timestamp.toDate();
        }
      } else {
        // global deadline

        var durationInMin = this.availabilityConfig.defaultDeadlineInMinutes;
        //var durationInMin = 60;
        var MS_PER_MINUTE = 60000;
        var enddate = this.data.timeSlots.start.timestampToDate;
        var calc = new Date(enddate - durationInMin * MS_PER_MINUTE);

        // V2: also working
        // var startdate = new Date(enddate);
        // const output = new Date(startdate.setUTCMinutes(enddate.getUTCMinutes() - durationInMin));

        if (withFormat) {
          // return this.timestampConverter(calc);
          const options = {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          };
          return calc.toLocaleDateString("de-DE", options);
        } else {
          return calc;
        }
      }
    },
    checkPermission(item) {
      if (item == "" || item == undefined) {
        return true;
      } else {
        return this.$store.getters["organization/checkPermissionByID"](item);
      }
    },
    updateResponse() {
      if (this.checkIfSupport || this.checkIfItService) {
        alert(
          "Fehler: Du verwendest ein IT-Service- oder Support-Konto und kannst keine eigenen Rückmeldungen abgeben."
        );
      } else if (!this.checkPermission(this.manageOwnResponsePermission)) {
        alert("Fehler: Du bist nicht berechtigt, diese Aktion auszuführen.");
      } else if (
        this.request.config.attendance.isLimited &&
        !this.request.userIsInvited
      ) {
        alert("Fehler: Die Teilnahme an dieser Veranstaltung ist beschränkt.");
      } else if (this.deadlineHasExpired) {
        alert(
          "Fehler: Die Rückmeldefrist ist bereits abgelaufen. Eine Rückmeldung kann nicht mehr abgegeben werden."
        );
      } else if (
        this.formIsValid &&
        this.responseIsBinding &&
        !this.userResponse.meta.responseAvailable
      ) {
        const res = confirm(
          "Abgegebene Rückmeldungen sind verbindlich. Eine abgegebene Rückmeldung kann anschließend nicht mehr geändert werden.\n\nRückmeldung speichern?"
        );
        if (res == true) {
          this.updateRequestResponse();
          this.userResponse.meta.responseAvailable = true;
        }
      } else if (this.formIsValid) {
        this.updateRequestResponse();
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    updateRequestResponse() {
      this.isLoading = true;
      return this.$store
        .dispatch(`${AVAILABILITY}${UPDATE_REQUEST_RESPONSE}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,

          uid: auth.currentUser.uid,
          status: this.convertedStatusToArray,
          comment: this.userResponse.comment,
        })
        .then(() => {
          this.helpers.isLoading = false;
          this.helpers.snackbar.message = "Rückmeldung gespeichert.";
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => {
          this.helpers.snackbar.message = error;
          this.helpers.snackbar.isVisible = true;
        });
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>

<style></style>
