<template>
  <div>
    <span v-if="totalConfirmations !== undefined">
      <v-chip
        label
        :small="small"
        :x-small="xSmall"
        color="green lighten-4"
        :class="['mr-1', 'green--text', 'text--darken-4', marginClass]"
      >
        <v-icon small :x-small="xSmall">mdi-check-circle</v-icon>
        <span class="ml-1 font-weight-bold">{{ totalConfirmations }}</span>
      </v-chip>
    </span>
    <span v-if="totalIfNecessary !== undefined">
      <v-chip
        label
        :small="small"
        :x-small="xSmall"
        color="orange lighten-4"
        :class="['mr-1', 'orange--text', 'text--darken-4', marginClass]"
      >
        <v-icon small :x-small="xSmall">mdi-minus-circle</v-icon>
        <span class="ml-1 font-weight-bold">{{ totalIfNecessary }}</span>
      </v-chip>
    </span>
    <span v-if="totalCancellations !== undefined">
      <v-chip
        label
        :small="small"
        :x-small="xSmall"
        color="red lighten-4"
        :class="['mr-1', 'red--text', 'text--darken-4', marginClass]"
      >
        <v-icon small :x-small="xSmall">mdi-close-circle</v-icon>
        <span class="ml-1 font-weight-bold">{{ totalCancellations }}</span>
      </v-chip>
    </span>
    <span v-if="totalNotInTown !== undefined">
      <v-chip
        label
        :small="small"
        :x-small="xSmall"
        color="blue lighten-4"
        :class="['mr-1', 'blue--text', 'text--darken-4', marginClass]"
      >
        <v-icon small :x-small="xSmall">mdi-airplane</v-icon>
        <span class="ml-1 font-weight-bold">{{ totalNotInTown }}</span>
      </v-chip>
    </span>
    <span v-if="totalAwaitingResponse !== undefined">
      <v-chip
        label
        :small="small"
        :x-small="xSmall"
        color="brown lighten-4"
        :class="['mr-1', 'brown--text', 'text--darken-4', marginClass]"
      >
        <v-icon small :x-small="xSmall">mdi-help-circle</v-icon>
        <span class="ml-1 font-weight-bold">{{ totalAwaitingResponse }}</span>
      </v-chip>
    </span>
    <span v-if="totalResponses !== undefined">
      <v-chip
        label
        :small="small"
        :x-small="xSmall"
        color="blue-grey lighten-4"
        :class="['mr-1', 'blue-grey--text', 'text--darken-4', marginClass]"
      >
        <v-icon small :x-small="xSmall">mdi-sigma</v-icon>
        <span class="ml-1 font-weight-bold">{{ totalResponses }}</span>
      </v-chip>
    </span>
  </div>
</template>

<script>
export default {
  name: "response-statistics-card",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    marginClass: {
      type: String,
    },
    totalConfirmations: {
      type: Number,
    },
    totalIfNecessary: {
      type: Number,
    },
    totalCancellations: {
      type: Number,
    },
    totalNotInTown: {
      type: Number,
    },
    totalAwaitingResponse: {
      type: Number,
    },
    totalResponses: {
      type: Number,
    },
    totalGuests: {
      type: Number,
    },
  },
};
</script>
