<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      elevation="2"
      type="article, divider, list-item-avatar, list-item-avatar, list-item-avatar"
    ></v-skeleton-loader>
    <v-card v-else :min-width="minWidth" :max-width="maxWidth">
      <v-card-title>
        <span>{{ request.title }}</span>
        <v-spacer></v-spacer>
        <v-chip
          v-if="request.status !== 'planned'"
          :color="statusColor(request.status)"
          :class="statusTextClass(request.status)"
          >{{ statusText(request.status) }}</v-chip
        >
        <v-btn
          v-if="showCloseButton"
          :color="$vuetify.theme.dark ? '' : 'secondary'"
          icon
          @click="$emit('closeEvent')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        <v-chip
          small
          :color="`${getMaterialColor(request.type.color)} lighten-4`"
          :class="`${getMaterialColor(
            request.type.color
          )}--text text--darken-4 font-weight-medium`"
        >
          {{ request.type.title }}
        </v-chip>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-clock-start</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>
          {{ timestampConverter(request.timeSlots.start.timestamp) }}
          Uhr
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-clock-end</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>
          {{ timestampConverter(request.timeSlots.end.timestamp) }}
          Uhr
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="request.location.name">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>{{ request.location.name }}</v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="request.description">
        <v-list-item-icon>
          <v-icon>mdi-calendar-text</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle style="white-space: normal">{{
          request.description
        }}</v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="request.config.organizer.isAppointed">
        <v-list-item-icon>
          <v-icon>mdi-account-hard-hat</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle style="white-space: normal"
          >Organisation:
          <v-chip
            v-if="
              request.config.organizer.description !== null &&
              request.config.organizer.description !== ''
            "
            label
            small
            class="ml-2 my-2"
            >{{ request.config.organizer.description }}</v-chip
          >
          <v-chip
            v-for="item in request.config.organizer.personData"
            :key="item.uid"
            label
            small
            class="ml-2 my-2"
            ><v-icon small left>mdi-account-circle</v-icon
            >{{ item.displayName }}</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="request.config.attendance.isLimited">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle style="white-space: normal"
          >Veranstaltung für:
          <v-chip
            v-if="
              request.config.attendance.description !== null &&
              request.config.attendance.description !== ''
            "
            label
            small
            class="ml-2 my-2"
            >{{ request.config.attendance.description }}</v-chip
          >
          <v-chip
            v-for="item in request.config.attendance.allowedUserGroups"
            :key="item.id"
            label
            small
            class="ml-2 my-2"
            ><v-icon small left>mdi-folder-account</v-icon
            >{{ item.title }}</v-chip
          ><v-chip
            v-for="item in request.config.attendance.allowedPersons"
            :key="item.uid"
            label
            small
            class="ml-2 my-2"
            ><v-icon small left>mdi-account-circle</v-icon
            >{{ item.displayName }}</v-chip
          ></v-list-item-subtitle
        >
      </v-list-item>
      <v-card-actions v-if="showDetailsButton"
        ><v-spacer></v-spacer
        ><v-btn
          small
          depressed
          :to="{
            name: 'availability-request-details-tab-general',
            params: { itemId: request.meta.id },
          }"
          ><v-icon small class="mr-1">mdi-information-outline</v-icon
          >Details</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: "request-details-card",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      required: false,
      default: undefined,
    },
    maxWidth: {
      required: false,
      default: undefined,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    showDetailsButton: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Object,
    },
  },
  methods: {
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
        case "#F44336":
          return "red";
        case "#E91E63FF":
        case "#E91E63":
          return "pink";
        case "#9C27B0FF":
        case "#9C27B0":
          return "purple";
        case "#673AB7FF":
        case "#673AB7":
          return "deep-purple";
        case "#3F51B5FF":
        case "#3F51B5":
          return "indigo";
        case "#2196F3FF":
        case "#2196F3":
          return "blue";
        case "#03A9F4FF":
        case "#03A9F4":
          return "light-blue";
        case "#00BCD4FF":
        case "#00BCD4":
          return "cyan";
        case "#009688FF":
        case "#009688":
          return "teal";
        case "#4CAF50FF":
        case "#4CAF50":
          return "green";
        case "#8BC34AFF":
        case "#8BC34A":
          return "light-green";
        case "#CDDC39FF":
        case "#CDDC39":
          return "lime";
        case "#FFEB3BFF":
        case "#FFEB3B":
          return "yellow";
        case "#FFC107FF":
        case "#FFC107":
          return "amber";
        case "#FF9800FF":
        case "#FF9800":
          return "orange";
        case "#FF5722FF":
        case "#FF5722":
          return "deep-orange";
        case "#795548FF":
        case "#795548":
          return "brown";
        case "#607D8BFF":
        case "#607D8B":
          return "blue-grey";
        case "#9E9E9EFF":
        case "#9E9E9E":
          return "grey";
        default:
          return "grey";
      }
    },
    statusText(status) {
      switch (status) {
        case "planned":
          return "Geplant";
        case "postponed":
          return "Verschoben";
        case "canceled":
          return "Abgesagt";
        case "finished":
          return "Abgeschlossen";
        case "deletion started":
          return "Löschung gestartet";
        default:
          break;
      }
    },
    statusColor(status) {
      switch (status) {
        case "planned":
          return "blue lighten-4";
        case "postponed":
          return "orange lighten-4";
        case "canceled":
          return "red lighten-4";
        case "finished":
          return "green lighten-4";
        case "deletion started":
          return "red lighten-4";
        default:
          break;
      }
    },
    statusTextClass(status) {
      switch (status) {
        case "planned":
          return "font-weight-medium blue--text text--darken-4";
        case "postponed":
          return "font-weight-medium orange--text text--darken-4";
        case "canceled":
          return "font-weight-medium red--text text--darken-4";
        case "finished":
          return "font-weight-medium green--text text--darken-4";
        case "deletion started":
          return "font-weight-medium red--text text--darken-4";
        default:
          break;
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
  computed: {
    attendeeItems() {
      return [];
    },
  },
};
</script>
