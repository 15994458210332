<template>
  <div>
    <v-item-group v-model="selectionLocal" mandatory class="mt-n1">
      <v-container>
        <v-row justify="center">
          <v-item v-slot="{ active }" value="0">
            <v-col
              v-if="active"
              cols="12"
              lg="3"
              md="3"
              sm="3"
              style="padding: 6px !important"
              hidden
            >
              <v-card
                color="blue-grey lighten-5"
                class="d-flex align-center rounded-md"
                dark
                height="40"
                disabled
                flat
                :style="active ? `border: 3px solid #263238 !important` : ''"
              >
                <v-row>
                  <v-col cols="12" md="12" class="text-center mt-1">
                    <v-list-item-subtitle
                      class="font-weight-medium blue-grey--text text--darken-4"
                      >Aktuell: Keine Rückmeldung</v-list-item-subtitle
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-item>
          <v-col
            v-for="(item, index) in items"
            :key="index"
            cols="6"
            lg="3"
            md="3"
            sm="3"
            style="padding: 6px !important"
          >
            <v-item v-slot="{ active }" :value="item.value">
              <v-card
                :color="
                  $vuetify.theme.dark
                    ? `${item.color} lighten-4`
                    : `${item.color} lighten-5`
                "
                class="d-flex align-center rounded-lg"
                dark
                :disabled="item.disabled || disabled"
                height="70"
                @click="selectionLocal = item.value"
                flat
                :style="getBorderStyle(active, item.borderColor)"
              >
                <v-row>
                  <v-col cols="12" md="12">
                    <v-list-item three-line class="text-center mt-1">
                      <v-list-item-content>
                        <v-progress-circular
                          v-if="item.loading"
                          indeterminate
                          :color="`${item.color} darken-4`"
                        ></v-progress-circular>
                        <div v-else>
                          <v-icon
                            :color="
                              $vuetify.theme.dark
                                ? `${item.color} darken-4`
                                : `${item.color} darken-4`
                            "
                            >{{ item.icon }}</v-icon
                          >
                        </div>
                        <v-list-item-subtitle
                          class="mt-n2 font-weight-medium"
                          :class="
                            $vuetify.theme.dark
                              ? `${item.color}--text text--darken-4`
                              : `${item.color}--text text--darken-4`
                          "
                          >{{ item.text }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
  </div>
</template>

<script>
export default {
  name: "button-quick-select-editor",
  model: {
    prop: "selection",
    event: "selectionChange",
  },
  props: {
    selection: {},
    items: {
      type: Array,
      required: false,
      default: () => [
        {
          icon: "mdi-check-circle",
          value: "1",
          loading: false,
          text: "Verfügbar",
          borderColor: { dark: "#1B5E20", light: "#1B5E20" },
          color: "green",
        },
        {
          icon: "mdi-minus-circle",
          value: "3",
          loading: false,
          text: "Wenn notwendig",
          borderColor: { dark: "#E65100", light: "#E65100" },
          color: "orange",
        },
        {
          icon: "mdi-close-circle",
          value: "2",
          loading: false,
          text: "Nicht verfügbar",
          borderColor: { dark: "#B71C1C", light: "#B71C1C" },
          color: "red",
        },
        {
          icon: "mdi-airplane",
          value: "4",
          loading: false,
          text: "Nicht in Stadt",
          borderColor: { dark: "#0D47A1", light: "#0D47A1" },
          color: "blue",
        },
      ],
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    selectionLocal: {
      get: function () {
        return this.selection;
      },
      set: function (value) {
        this.$emit("selectionChange", value);
      },
    },
  },
  methods: {
    getBorderStyle(active, borderColor) {
      if (this.$vuetify.theme.dark) {
        if (active) {
          return `border: 3px solid ${borderColor.dark} !important`;
        } else {
          return ``;
        }
      } else {
        if (active) {
          return `border: 3px solid ${borderColor.light} !important`;
        } else {
          return ``;
        }
      }
    },
  },
};
</script>

<style></style>
